import { ApplicationState, RootState } from "@/interfaces/StoreStateInterfaces";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import EmployeeRepository from "@/api/repositories/EmployeeRepository";
import Application from "@/models/Application";
import UserRepository from "@/api/repositories/UserRepository";
import { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import User from "@/models/User.model";
import { ApplicationStatus } from "@/enum/ApplicationStatus.enum";
import { StatusEnum } from "@/enum/Status.enum";
import StatusJob from "@/models/status";
import ApplicationJob from "@/models/ApplicationJob";

export const APPLICATION_STORE_NAME = "application";

export const enum ApplicationStoreActions {
  GET_CURRENT_COMPANY = "GET_CURRENT_COMPANY",
  GET_APPLICATIONS = "GET_APPLICATIONS",
  REQUEST_APPLICATION = "REQUEST_APPLICATION",
  FETCH_NEWSLETTER_SUBSCRIBERS = "FETCH_NEWSLETTER_SUBSCRIBERS",
  CHECK_DUPLICATES = "CHECK_DUPLICATES",
  CHECK_JOBS = "CHECK_JOBS",
  DOWNLOAD_NEWSLETTER_SHEET = "DOWNLOAD_NEWSLETTER_SHEET",
  DOWNLOAD_APPLICATION_PDF = "DOWNLOAD_APPLICATION_PDF",
  ASSIGN_APPLICATION = "ASSIGN_APPLICATION",
  CHECK_INFORMATION_JOB = "CHECK_INFORMATION_JOB",
}

export const enum ApplicationStoreMutations {
  SET_COMPANY = "SET_COMPANY",
  CLEAR_STORE = "CLEAR_STORE",
  SET_APPLICATIONS = "SET_APPLICATIONS",
  OVERWRITE_STATUS_APPLICATIONS = "OVERWRITE_STATUS_APPLICATIONS",
  UPDATE_APPLICATION = "UPDATE_APPLICATION",
  SET_REQUEST_APPLICATION = "SET_REQUEST_APPLICATION",
  SET_DUPLICATES = "SET_DUPLICATES",
  SET_NEWSLETTER_COUNT = "SET_NEWSLETTER_COUNT",
  SET_CHECK_JOBS = "SET_CHECK_JOBS",
  SET_INTERVAL_ID = "SET_INTERVAL_ID",
  SET_STATUS = "SET_STATUS",
}

export const enum ApplicationStoreGetters {
  CURRENT_COMPANY = "CURRENT_COMPANY",
  CURRENT_APPLICATION = "CURRENT_APPLICATION",
  NUMBER_OF_SUBSCRIBERS = "NUMBER_OF_SUBSCRIBERS",
  DUPLICATIONS = "DUPLICATIONS",
  CHECK_JOBS = "CHECK_JOBS",
  STATUS = "STATUS",
  "INTERVAL_ID" = "INTERVAL_ID"
}

function initialApplicationState(): ApplicationState {
  return {
    application: [],
    duplicates: [],
    checkJobs: [],
    status: [],
    intervalId: undefined,
    subscriberNumber: 0
  };
}

const store: ApplicationState = initialApplicationState();

const actions: ActionTree<ApplicationState, RootState> = {
  [ApplicationStoreActions.GET_APPLICATIONS]: async (
    { commit },
    payload: { status?: ApplicationStatus, overwrite?: boolean }
  ): Promise<Application[] | null> => {
    const response = await EmployeeRepository.getApplications();
    const application = (response.data as any[]).map((v) =>
      Application.parseFromObject(v)
    );
    if(payload.overwrite !== undefined && payload.overwrite){
      commit(ApplicationStoreMutations.OVERWRITE_STATUS_APPLICATIONS, application);
    } else {
      commit(ApplicationStoreMutations.SET_APPLICATIONS, application);
    }
    return application;
  },
  
  [ApplicationStoreActions.CHECK_INFORMATION_JOB]: async (
      { commit },
      payload: {
        applicationId: string
      }
  ): Promise<StatusJob> => {
    const status = await EmployeeRepository.checkJobInformation(payload.applicationId);
    const job = StatusJob.parseFromObject(status.data);
    return job;
  },
  
  [ApplicationStoreActions.REQUEST_APPLICATION]: async (
    { commit },
    payload: {
      applicationId: string,
      status: ApplicationStatus,
      easyCardId?: string,
      mandatRef?: string,
      description?: string
    }
  ): Promise<StatusJob> => {
    const response = await EmployeeRepository.requestApplication(
      payload.applicationId,
      payload.status,
      payload.easyCardId,
      payload.mandatRef,
      payload.description
    );
    const changedApplication = ApplicationJob.parseFromObject(response.data);
    commit(ApplicationStoreMutations.UPDATE_APPLICATION, changedApplication.application ?? changedApplication);
    return changedApplication.job;
  },

  [ApplicationStoreActions.CHECK_JOBS]: async (
      { commit },
      payload: {
        job: StatusJob
      }
  ): Promise<null> => {
    const response = await EmployeeRepository.getApplication(payload.job.applicationId);
    const obj = Application.parseFromObject(response.data);
    commit(ApplicationStoreMutations.UPDATE_APPLICATION, obj!);
    return null;
  },

  [ApplicationStoreActions.CHECK_DUPLICATES]: async (
    { commit }, payload: {
      user: User
    }
  ): Promise<User[] | null> => {
    const response = await EmployeeRepository.checkForDuplicates(payload.user);
    const user = (response.data as any[]).map((v) =>
      User.parseFromObject(v)
    );
    commit(ApplicationStoreMutations.SET_DUPLICATES, user);
    return user;
  },

  [ApplicationStoreActions.ASSIGN_APPLICATION]: async (
    { commit }, payload: {
      app: Application,
      user: User
    }
  ): Promise<any> => {
    const response = await EmployeeRepository.assignApplication(payload.app, payload.user);
    const changedApplication = Application.parseFromObject(response.data);
    commit(ApplicationStoreMutations.UPDATE_APPLICATION, changedApplication);
    return response;
  },

  [ApplicationStoreActions.FETCH_NEWSLETTER_SUBSCRIBERS]: async (
    { commit },
  ): Promise<number> => {
    const response = await UserRepository.getNewsletterSubscribers();
    commit(ApplicationStoreMutations.SET_NEWSLETTER_COUNT, response.data.count);
    return response.data.count;
  },
  [ApplicationStoreActions.DOWNLOAD_NEWSLETTER_SHEET]: async (
    { commit },
  ): Promise<AxiosResponse> => {
    // Get Download file as Blob
    const res = await UserRepository.downloadNewsletterFile();

    // Find the FileName using the Content-Disposition Header
    const header = res.headers["content-disposition"] || "";
    const fileName: string = header.split('filename=')[1].split(';')[0];

    // Initialize a File Download using the Blobs Data
    fileDownload(res.data, fileName.replace(/"/g, ''));

    // Return the initial Response
    return res;
  },
  [ApplicationStoreActions.DOWNLOAD_APPLICATION_PDF]: async (
    { commit }, payload: {
      application: Application,
      prefix: string
    }
  ): Promise<AxiosResponse> => {
    // Get Download file as Blob
    const res = await EmployeeRepository.downloadApplicationPdf(
      payload.application
    );

    // Find the FileName using the Content-Disposition Header
    const fileName: string = `${payload.prefix}${payload.application.referenceNumber}.pdf`;

    // Initialize a File Download using the Blobs Data
    fileDownload(res.data, fileName);

    // Return the initial Response
    return res;
  }
};

const mutations: MutationTree<ApplicationState> = {
  [ApplicationStoreMutations.CLEAR_STORE]: (state: ApplicationState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialApplicationState());
  },
  [ApplicationStoreMutations.SET_APPLICATIONS]: (
    state: ApplicationState,
    value: Application[]
  ) => {
    state.application = value;
  },
  [ApplicationStoreMutations.SET_INTERVAL_ID]: (
    state: ApplicationState,
    value: number | undefined
  ) => {
    state.intervalId = value;
  },
  [ApplicationStoreMutations.SET_CHECK_JOBS]: (
    state: ApplicationState,
    value: string
  ) => {
    const jobs = [...state.checkJobs];
    const filter = jobs.filter(v => v != value);
    state.checkJobs = filter;
  },
  [ApplicationStoreMutations.SET_STATUS]: (
    state: ApplicationState,
    value: StatusJob[] | undefined
  ) => {
    if(value !== undefined){
      state.status = value;
    } else{
      state.status = [];
    }

  },
  [ApplicationStoreMutations.SET_DUPLICATES]: (
    state: ApplicationState,
    value: User[]
  ) => {
    state.duplicates = value;
  },
  [ApplicationStoreMutations.OVERWRITE_STATUS_APPLICATIONS]: (
    state: ApplicationState,
    value: Application[]
  ) => {
    state.application = [...value];
  },
  [ApplicationStoreMutations.UPDATE_APPLICATION]: (
    state: ApplicationState,
    value: Application
  ) => {

    const app = [...state.application];
    const index = app.findIndex(e =>  e.id === value.id);
    app.splice(index, 1, value);
    state.application = app;
  },
  [ApplicationStoreMutations.SET_NEWSLETTER_COUNT]: (
    state: ApplicationState,
    value: number
  ) => {
    state.subscriberNumber = value;
  }
};

const getters: GetterTree<ApplicationState, RootState> = {
  [ApplicationStoreGetters.CURRENT_APPLICATION]: (state: ApplicationState) => {
    return state.application;
  },
  [ApplicationStoreGetters.NUMBER_OF_SUBSCRIBERS]: (state: ApplicationState) => {
    return state.subscriberNumber;
  },
  [ApplicationStoreGetters.DUPLICATIONS]: (state: ApplicationState) => {
    return state.duplicates;
  },
  [ApplicationStoreGetters.STATUS]: (state: ApplicationState) => {
    return state.status;
  },
  [ApplicationStoreGetters.CHECK_JOBS]: (state: ApplicationState) => {
    return state.checkJobs;
  }
};

const applicationStore: Module<ApplicationState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters
};

export default applicationStore;

import { AxiosResponse } from "axios";
import { AUTH_CLIENT } from "../misc/AuthClient";
import { ENTITY_CLIENT } from "@/api/misc/EntityClient";
import Application from "@/models/Application";
import { ApplicationState } from "@/interfaces/StoreStateInterfaces";
import { ApplicationStatus } from "@/enum/ApplicationStatus.enum";
import User from "@/models/User.model";
import { UserRoles } from "@/enum/UserRoles.enum";

export default class EmployeeRepository {
  public static getApplications(status?: ApplicationStatus): Promise<AxiosResponse> {
    if (status === undefined) return ENTITY_CLIENT.get("/applications");
    return ENTITY_CLIENT.get("/applications", { params: { status: status } });
  }

  public static getApplication(id?: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get(`/applications/${id}`);
  }

  public static checkJobInformation(accept?: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get(`/applications/job/${accept}/status`);
  }

  public static getUsersByRole(payload: { role: UserRoles }): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get("/users", { params: { role: payload.role } });
  }

  public static deleteUser(payload: { user: User }): Promise<AxiosResponse> {
    return ENTITY_CLIENT.delete("/users/" + payload.user._id);
  }

  public static updateUser(payload: { user: User }): Promise<AxiosResponse> {
    return ENTITY_CLIENT.put("/users/" + payload.user._id, payload.user);
  }

  public static changePasswordForce(payload: { user: User, password: string }): Promise<AxiosResponse> {
    return ENTITY_CLIENT.put("/users/change-password-force/" + payload.user._id, { newPassword: payload.password });
  }

  public static requestApplication(applicationId: string,
                                   status: ApplicationStatus, easyCardId?: string,
                                   mandatRef?: string, description?: string): Promise<AxiosResponse> {
    if (description === undefined) return ENTITY_CLIENT.patch("/applications/" + applicationId, {
      status: status, easyCardId: easyCardId,
      mandateReference: mandatRef
    });
    return ENTITY_CLIENT.patch("/applications/" + applicationId, { status: status, message: description });
  }

  public static checkForDuplicates(user: User): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post("/users/duplicates", user);
  }

  public static assignApplication(app: Application, user: User): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post("/applications/" + app.id + "/assign", { "userId": user._id });
  }

  public static downloadApplicationPdf(application: Application): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get(`/applications/${application.id}/download`, { responseType: "blob" });
  }
}

import { AxiosResponse } from "axios";
import { AUTH_CLIENT } from "../misc/AuthClient";
import { ENTITY_CLIENT } from "@/api/misc/EntityClient";
import Application from "@/models/Application";
import { UserRoles } from "@/enum/UserRoles.enum";
import EasyCard from "@/models/EasyCard.model";
import { Buffer } from "buffer";
import { ApplicationCreatedResponse } from "@/api/repositories/application.models";

export default class AuthRepository {
  public static login(payload: { email: string, password: string }): Promise<AxiosResponse> {
    return AUTH_CLIENT.post("auth/login", payload);
  }

  public static createNewUser(payload: {
    email: string, password: string,
    firstName: string, lastName: string, role: UserRoles
  }): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post("/users", payload);
  }

  public static orderEasyCard(payload: {
    easyCards: EasyCard[],
    comment: string
  }): Promise<AxiosResponse<ApplicationCreatedResponse>> {
    return ENTITY_CLIENT.post("/users/order/easy-cards", payload);
  }

  public static initiatePasswordReset(email: string): Promise<AxiosResponse> {
    return AUTH_CLIENT.post("/auth/reset-password", {
      email: email
    });
  }

  public static resetPassword(token: string, password: string): Promise<AxiosResponse> {
    return AUTH_CLIENT.put("/auth/reset-password", {
      token: token,
      password: password
    });
  }

  public static register(app: Application): Promise<AxiosResponse<ApplicationCreatedResponse>> {
    const ec = app.easyCards[0];
    return AUTH_CLIENT.post("/auth/register", {
      easyCards: ec,
      user: app.user,
      comment: app.comment,
      licensePlate: app.licensePlate
    });
  }

  public static verifyEmailWithToken(token: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post("api/account/confirm-email-change", {
      token: token
    });
  }
}

import {AccountType} from '@/enum/AccountType.enum';
import Parseable from '@/misc/Parseable';
import User from '@/models/User.model';
import { StatusEnum } from "@/enum/Status.enum";
import StatusJob from "@/models/status";
import Application from "@/models/Application";

export default class ApplicationJob extends Parseable {
  public job!: StatusJob;
  public application!: Application;

  public static parseFromObject(object: any): ApplicationJob {
      const job = new ApplicationJob();
      Object.assign(job, object);
      return job;
  }

  public parseToObject(): Partial<ApplicationJob> {
      return { ...this };
  }
}


import Vue from 'vue';
import VueRouter from 'vue-router';
import store, { StoreActions } from '@/store';
import {
  ROUTE_INITIATE_RESET_PASSWORD,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_REGISTER,
  ROUTE_EMPLOYEE_LOGIN,
  ROUTE_EMPLOYEE_PORTAL_REQUESTS,
  ROUTE_PASSWORD_REQUESTED,
  ROUTE_USER_INFORMATION,
  ROUTE_USER_CREATED,
  ROUTE_RESET_PASSWORD, ROUTE_LOG_OUT, ROUTE_RESEND_WELCOME_MAIL, ROUTE_WELCOME_REQUESTED, ROUTE_CONFIRM_MAIL_CHANGED,
} from '@/router/routes';
import {AuthStoreGetters} from "@/store/auth.store";

Vue.use(VueRouter);

const routes = [
  {
    path: '/register',
    name: ROUTE_REGISTER,
    component: () => import(/* webpackChunkName: "register" */ '../views/Authentication/Register.view.vue'),
  },
  {
    path: '/user-created',
    name: ROUTE_USER_CREATED,
    component: () => import(/* webpackChunkName: "userCreated" */ '../views/Authentication/AccountCreated.view.vue'),
  },
  {
    path: '/login',
    name: ROUTE_LOGIN,
    component: () => import(/* webpackChunkName: "login" */ '../views/Authentication/Login.view.vue'),
  },
  {
    path: '/logout',
    name: ROUTE_LOG_OUT,
    component: () => import(/* webpackChunkName: "logout" */ '../views/Authentication/Logout.view.vue'),
  },
  {
    path: '/employee-login',
    name: ROUTE_EMPLOYEE_LOGIN,
    component: () => import(/* webpackChunkName: "logout" */ '../views/Authentication/Employee.Login.view.vue'),
  },
  {
    path: '/new-welcome-mail',
    name: ROUTE_RESEND_WELCOME_MAIL,
    component: () => import(/* webpackChunkName: "resendWelcomeMail" */ '../views/Authentication/ResendWelcomeMail.view.vue'),
  },
  {
    path: '/reset-password',
    name: ROUTE_INITIATE_RESET_PASSWORD,
    component: () => import(/* webpackChunkName: "initiateResetPassword" */ '../views/Authentication/InitiateResetPassword.view.vue'),
  },
  {
    path: '/employee-portal-requests',
    name: ROUTE_EMPLOYEE_PORTAL_REQUESTS,
    component: () => import(/* webpackChunkName: "initiateResetPassword" */ '../views/Employee.Portal.Requests.view.vue'),
  },
  {
    path: '/password-requested',
    name: ROUTE_PASSWORD_REQUESTED,
    component: () => import(/* webpackChunkName: "passwordRequested" */ '../views/Authentication/PasswordRequested.view.vue'),
  },
  {
    path: '/confirm-mail',
    name: ROUTE_CONFIRM_MAIL_CHANGED,
    component: () => import(/* webpackChunkName: "confirmMail" */ '../views/ConfirmMail.view.vue'),
  },
  {
    path: '/welcome-mail-requested',
    name: ROUTE_WELCOME_REQUESTED,
    component: () => import(/* webpackChunkName: "welcomeRequested" */ '../views/Authentication/WelcomeRequested.view.vue'),
  },
  {
    path: '/set-new-password',
    name: ROUTE_RESET_PASSWORD,
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/Authentication/Password.view.vue'),
  },
  {
    path: '/home',
    name: ROUTE_HOME,
    beforeEnter: async (to: any, from: any, next: any) => {
      // Check if token is set. If not, redirect to login
      if (store.getters[`auth/${AuthStoreGetters.TOKEN}`]) {
        await next();
      } else {
        await store.dispatch(StoreActions.CLEAR_ALL_STORES);
        await next({ name: ROUTE_LOGIN });
      }
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.view.vue'),
    children: [
      {
        path: '/user-information',
        name: ROUTE_USER_INFORMATION,
        component: () => import(/* webpackChunkName: "userInformation" */ '../views/UserInformation.view.vue'),
      }
    ],
  },
  {
    path: '*',
    redirect: { name: ROUTE_HOME }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

import AuthRepository from "@/api/repositories/AuthRepository";
import { AuthState, RootState } from "@/interfaces/StoreStateInterfaces";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import User from "@/models/User.model";
import UserRepository from "@/api/repositories/UserRepository";
import EasyCard from "@/models/EasyCard.model";
import Application from "@/models/Application";
import { UserRoles } from "@/enum/UserRoles.enum";
import EmployeeRepository from "@/api/repositories/EmployeeRepository";
import fileDownload from "js-file-download";
import { Buffer } from "buffer";
import { ApplicationBufferResponse } from "@/api/repositories/application.models";

export const AUTH_STORE_NAME = "auth";

export const enum AuthStoreActions {
  LOGIN = "LOGIN",
  LOGIN_WITH_TOKEN = "LOGIN_WITH_TOKEN",
  INITIATE_PASSWORD_RESET = "INITIATE_PASSWORD_RESET",
  RESET_PASSWORD = "RESET_PASSWORD",
  REGISTER_RESTAURATEUR = "REGISTER_RESTAURATEUR",
  UPDATE_USER = "UPDATE_USER",
  GET_USER = "GET_USER",
  CREATE_NEW_USER = "CREATE_NEW_USER",
  ORDER_EASY_CARDS = "ORDER_EASY_CARDS",
  PUT_USER = "PUT_USER",
  GET_USER_BY_ROLE = "GET_USER_BY_ROLE",
  CHANGE_PASSWORD_FORCE = "CHANGE_PASSWORD_FORCE",
  DELETE_USER = "DELETE_USER"
}

export const enum AuthStoreMutations {
  ADD_NEW_USER_TO_LIST = "ADD_NEW_USER_TO_LIST",
  SET_ROLED_USER = "SET_ROLED_USER",
  DELETE_USER = "DELETE_USER",
  SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN",
  SET_TOKEN = "SET_TOKEN",
  SET_USER = "SET_USER",
  UPDATE_USER = "UPDATE_USER",
  CLEAR_STORE = "CLEAR_STORE"
}

export const enum AuthStoreGetters {
  ROLED_USER = "ROLED_USER",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  TOKEN = "TOKEN",
  CURRENT_USER = "CURRENT_USER"
}

function initialAuthState(): AuthState {
  return {
    roledUser: undefined,
    refreshToken: undefined,
    token: undefined,
    currentUser: undefined
  };
}

const store: AuthState = initialAuthState();

const actions: ActionTree<AuthState, RootState> = {
  [AuthStoreActions.CREATE_NEW_USER]: async ({ commit }, payload: {
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    role: UserRoles
  })
    : Promise<User | null> => {
    const response = await AuthRepository.createNewUser(payload);
    const user = User.parseFromObject(response.data);
    commit(AuthStoreMutations.ADD_NEW_USER_TO_LIST, user);
    return user;
  },
  [AuthStoreActions.ORDER_EASY_CARDS]: async ({ commit }, payload: { easyCards: EasyCard[], comment: string })
    : Promise<ApplicationBufferResponse> => {
    const response = await AuthRepository.orderEasyCard(payload);
    const app = Application.parseFromObject(response.data.application);
    return {
      app,
      buffer: new Uint8Array(response.data.pdf)
    };
  },
  [AuthStoreActions.PUT_USER]: async ({ commit },
                                      payload: { user: User })
    : Promise<User | null> => {
    const response = await EmployeeRepository.updateUser({ user: payload.user });
    commit(AuthStoreMutations.UPDATE_USER, payload.user);
    return null;
  },
  [AuthStoreActions.CHANGE_PASSWORD_FORCE]: async ({ commit },
                                                   payload: { user: User, password: string })
    : Promise<User | null> => {
    const response = await EmployeeRepository.changePasswordForce({ user: payload.user, password: payload.password });
    return null;
  },
  [AuthStoreActions.DELETE_USER]: async ({ commit },
                                         payload: { user: User })
    : Promise<User | null> => {
    const response = await EmployeeRepository.deleteUser({ user: payload.user });
    commit(AuthStoreMutations.DELETE_USER, payload.user);
    return payload.user;
  },
  [AuthStoreActions.GET_USER_BY_ROLE]: async ({ commit })
    : Promise<User[] | null> => {
    const response_one = await EmployeeRepository.getUsersByRole({ role: UserRoles.ADMIN });
    const response_two = await EmployeeRepository.getUsersByRole({ role: UserRoles.EMPLOYEE });
    const user_one = (response_one.data as any[]).map((v) =>
      User.parseFromObject(v)
    );
    const user_two = (response_two.data as any[]).map((v) =>
      User.parseFromObject(v)
    );
    user_one.push(...user_two);
    const user = user_one;
    commit(AuthStoreMutations.SET_ROLED_USER, user);
    return user;
  },
  [AuthStoreActions.LOGIN]: async ({ commit }, payload: { email: string, password: string })
    : Promise<User | null> => {
    const tokens = await AuthRepository.login(payload);

    // saves tokens to store
    commit(AuthStoreMutations.SET_TOKEN, tokens.data.access_token);
    commit(AuthStoreMutations.SET_REFRESH_TOKEN, tokens.data.refresh_token);

    const user = tokens.data.user;
    commit(AuthStoreMutations.SET_USER, user);
    return user;
  },
  [AuthStoreActions.LOGIN_WITH_TOKEN]: async ({ commit }, payload: { token: string, refreshToken: string })
    : Promise<User | null> => {
    // saves tokens to store
    commit(AuthStoreMutations.SET_TOKEN, payload.token);
    commit(AuthStoreMutations.SET_REFRESH_TOKEN, payload.refreshToken);
    return null;
  },
  [AuthStoreActions.INITIATE_PASSWORD_RESET]: async ({ commit }, email: string): Promise<any> => {
    const response = await AuthRepository.initiatePasswordReset(email);
    return response.data;
  },
  [AuthStoreActions.RESET_PASSWORD]: async ({ commit }, payload: { token: string, password: string }): Promise<any> => {
    const response = await AuthRepository.resetPassword(payload.token, payload.password);
    return response.data;
  },
  [AuthStoreActions.GET_USER]: async ({ commit }, id: string): Promise<any> => {
    const response = await UserRepository.getUserById(id);
    const user = User.parseFromObject(response.data);
    commit(AuthStoreMutations.SET_USER, user);
    return user;
  },
  [AuthStoreActions.UPDATE_USER]: async ({ commit }, payload: { id: string, user: User }): Promise<any> => {
    const response = await UserRepository.updateUser(payload.id, payload.user);
    const updatedUser = User.parseFromObject(response.data);
    commit(AuthStoreMutations.SET_USER, updatedUser);
    return updatedUser;
  },
  [AuthStoreActions.REGISTER_RESTAURATEUR]: async ({ commit }, payload: {
    easyCards: EasyCard[],
    user: User,
    comment?: string,
    licensePlate?: string
  }): Promise<ApplicationBufferResponse> => {
    const application = Application.parseFromObject({
      easyCards: [payload.easyCards],
      user: payload.user,
      comment: payload.comment,
      licensePlate: payload.licensePlate || null
    });
    const response = await AuthRepository.register(application);
    const app = Application.parseFromObject(response.data.application);
    return {
      app,
      buffer: new Uint8Array(response.data.pdf)
    };
  }
};

const mutations: MutationTree<AuthState> = {
  [AuthStoreMutations.SET_TOKEN]: (state: AuthState, value: string | undefined) => {
    state.token = value;
  },
  [AuthStoreMutations.SET_REFRESH_TOKEN]: (state: AuthState, value: string | undefined) => {
    state.refreshToken = value;
  },
  [AuthStoreMutations.SET_USER]: (state: AuthState, value: User) => {
    state.currentUser = value;
  },
  [AuthStoreMutations.SET_ROLED_USER]: (state: AuthState, value: User[]) => {
    state.roledUser = value;
  },
  [AuthStoreMutations.UPDATE_USER]: (state: AuthState, value: User) => {
    const roles = [...state.roledUser!];
    const index = roles.findIndex((v, index) => v._id === value._id);
    roles.splice(index, 1, value);
    state.roledUser = roles;
  },
  [AuthStoreMutations.DELETE_USER]: (state: AuthState, value: User) => {
    const roles = [...state.roledUser!];
    const index = roles.findIndex((v, index) => v._id === value._id);
    roles.splice(index, 1);
    state.roledUser = roles;
  },
  [AuthStoreMutations.ADD_NEW_USER_TO_LIST]: (state: AuthState, value: User) => {
    const roles = [...state.roledUser!];
    roles?.push(value);
    state.roledUser = roles;
  },
  [AuthStoreMutations.CLEAR_STORE]: (state: AuthState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialAuthState());
  }
};

const getters: GetterTree<AuthState, RootState> = {
  [AuthStoreGetters.TOKEN]: (state: AuthState) => state.token,
  [AuthStoreGetters.REFRESH_TOKEN]: (state: AuthState) => state.refreshToken,
  [AuthStoreGetters.ROLED_USER]: (state: AuthState) => state.roledUser,
  [AuthStoreGetters.CURRENT_USER]: (state: AuthState) => {
    return state.currentUser;
  }
};

const authStore: Module<AuthState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters
};

export default authStore;

import {AccountType} from '@/enum/AccountType.enum';
import Parseable from '@/misc/Parseable';
import User from '@/models/User.model';
import EasyCard from "@/models/EasyCard.model";
import { ApplicationStatus } from "@/enum/ApplicationStatus.enum";

export default class Application extends Parseable {
  public id?: string;
  public _id?: string;
  public easyCards!: EasyCard[];
  public user!: User;
  public licensePlate?: string | null;
  public comment!: string;
  public createdAt?: Date;
  public updatedAt?: Date;
  public duplicatesCount?: number;
  public referenceNumber?: number;
  public status?: ApplicationStatus;
  public duplicates?: boolean;

  public static parseFromObject(object: any): Application {
      const app = new Application();
      Object.assign(app, object);
      return app;
  }

  public parseToObject(): Partial<Application> {
      return { ...this };
  }
}


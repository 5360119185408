import { AxiosResponse } from "axios";
import User from "@/models/User.model";
import { ENTITY_CLIENT } from "@/api/misc/EntityClient";

/**
 * repository that deals with all the api requests that have to do with users
 */
export default class UserRepository {
  private static baseUrl: string = "users";

  /**
   * updates the users information
   * @param id
   */
  public static getUserById(id: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get(`${this.baseUrl}/${id}`);
  }

  /**
   * Get User Mandate
   * @param id
   */
  public static getUserMandate(id: string): Promise<AxiosResponse<{
    DirectDebitID: string,
    BPInternalID: string,
    ObjectID: string
  } | undefined>> {
    return ENTITY_CLIENT.get(`${this.baseUrl}/${id}/mandate`);
  }

  /**
   * updates the users information
   * @param id
   * @param user
   */
  public static updateUser(id: string, user: User): Promise<AxiosResponse> {
    return ENTITY_CLIENT.put(`${this.baseUrl}/${id}`, user);
  }

  /**
   * updates the users information
   * @param email
   */
  public static resendWelcomeMail(email: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post(`${this.baseUrl}/resend-welcome-mail`, {
      email
    });
  }

  /**
   * updates the users password
   * @param id
   * @param oldPassword
   * @param newPassword
   */
  public static changePassword(id: string, oldPassword: string, newPassword: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.put(`${this.baseUrl}/change-password/${id}`, {
      oldPassword,
      newPassword
    });
  }

  /**
   * confirms the email change
   * @param token
   */
  public static confirmMailChange(token: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.put(`${this.baseUrl}/change-email/${token}`);
  }

  public static getNewsletterSubscribers(): Promise<AxiosResponse<{ count: number }>> {
    return ENTITY_CLIENT.get(`${this.baseUrl}/newsletter/count`);
  }

  public static downloadNewsletterFile(): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get(`${this.baseUrl}/newsletter/download`, {
      responseType: "blob"
    });
  }
}

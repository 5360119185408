import Parseable from "@/misc/Parseable";
import Address from "@/models/Address";
import { Gender } from "@/enum/UserTitle.enum";
import { UserDegree } from "@/enum/UserDegree";
import BillingDetails from "@/models/BillingDetails";
import { UserType } from "@/enum/UserType.enum";
import { UserRoles } from "@/enum/UserRoles.enum";

export default class User extends Parseable {
  public _id?: string;
  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public address?: Address;
  public phone?: string;
  public password?: string;
  public company?: string;
  public degree?: UserDegree;
  public gender?: Gender | null;
  public sapIds?: {
    internalUserId?: string
  }
  public billingDetails?: BillingDetails;
  public type?: UserType;
  public status?: boolean;
  public role?: UserRoles;
  public newsletterSubscribed?: boolean;

  public static parseFromObject(object: any): User {
    const user = new User();
    Object.assign(user, object);
    user.address = Address.parseFromObject(user.address);
    user.gender = user.gender ?? Gender.NOT_SET;
    user.billingDetails = BillingDetails.parseFromObject(user.billingDetails);
    return user;
  }

  public parseToObject(): Partial<User> {
    return { ...this };
  }
}

import {AccountType} from '@/enum/AccountType.enum';
import Parseable from '@/misc/Parseable';
import User from '@/models/User.model';
import { StatusEnum } from "@/enum/Status.enum";

export default class StatusJob extends Parseable {
  public applicationId!: string;
  public easyCardId!: string;
  public internalSapId!: string;
  public mandateReference!: string;
  public status!: StatusEnum;
  public _id!: string;
  public id!: string;

  public static parseFromObject(object: any): StatusJob {
      const job = new StatusJob();
      Object.assign(job, object);
      return job;
  }

  public parseToObject(): Partial<StatusJob> {
      return { ...this };
  }
}


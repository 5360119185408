import { Vue } from 'vue-property-decorator';
import router from '@/router';
import { ROUTE_LOGIN } from '@/router/routes';
import store, { StoreActions } from '@/store';
import { AuthStoreGetters } from '@/store/auth.store';
import axios, { AxiosInstance } from 'axios';
import ApiError from './ApiError';

const baseURL: string = process.env.VUE_APP_API_URL;
const axiosClient: AxiosInstance = axios.create({
  baseURL: baseURL,
});

/**
 * Sets the request interceptors.
 * Sets authorization header for each request.
 */
axiosClient.interceptors.request.use(
  (config) => {
    // OPTIMIZE this is not optimal, the token could be expired ...
    if (store.getters[`auth/${AuthStoreGetters.TOKEN}`]) {
      config.headers.authorization = `Bearer ${store.getters[`auth/${AuthStoreGetters.TOKEN}`]}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Sets the response interceptors. Normalizes the response and handles certain error cases.
 */
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401: // Unauthorized
            // Reset all stores:
            store.dispatch(StoreActions.CLEAR_ALL_STORES);
            router.push({ name: ROUTE_LOGIN });
            break;
        // case 403: // Forbidden
        //   Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.NO_PERMISSION');
        //   break;
        case 500: // any server error
          Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
          break;
        default:
          throw new ApiError(error.response.status, error.message, error.response.data.data);
      }
    } else {
      Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.NETWORK_ERROR');
    }
    return Promise.reject(error);
  }
);

export const ENTITY_CLIENT = axiosClient;
